export const endpointPath = {
  filterNV2: '/eerr/data/nv2',
  filterIdperiodo: '/eerr/data/idperiodo',
  filterIdempresa: '/eerr/data/idempresa',
  filterIdproyecto: '/eerr/data/idproyecto',
  filterCuentas: '/eerr/data/cuentas',
  filterDivkey: '/eerr/data/divkey',
  filterDatalistFilter: '/eerr/data_list_filtered/{dataset}/{filter}',
  
  // Deprecado
  sliceToken: '/eerr/nv2/year/consulta_sin_periodo_ni_cuenta/filter?',

  // Placeholder
  placeholderTest: 'https://jsonplaceholder.typicode.com/todos/1',

  // Login
  Login: '/app/init/login',

  // Config
  getExtraFiltros: '/config_data/getextrafiltros',
  setExtraFiltros: '/config_data/setextrafiltros/filter?',
  switchACPExtraFiltros: '/config_data/switchacp',

  // User data get
  userData: '/user_data',
  userList: '/users_get',
  userSearchdata: '/users_search_data',
  // User data put
  userDelete: '/users/desactivar_usuario/{id}',
  userPermisos: '/users/updatear_permisos/{id}?',
  userChangePass: '/users/change_password/{id}/{new_password}',
  userChangeRol: '/users/change_rol/{id}/{new_user_type}',
  userConfigViews: '/users/change_visualizaciones/{id}/{new_visualizaciones}',
  
  // User Create
  userCreate: '/users_create',

  // Cuentas Contables
  getMantenedorCuentasGetAll: '/plandecuentascontables/getall?',
  getDiferenciaEntrePlanes: '/plandecuentascontables/diferencias_entre_planes',
  getDiferenciaEntrePlanYBalance: '/plandecuentascontables/diferencias_entre_plan_y_balance',
  cuentaCreate: '/plandecuentascontables/crearcuenta',
  cuentaUpdate: '/plandecuentascontables/update',
  cuentaGetInfo: '/plandecuentascontables/cuentaindividual/{MainAccountId}',
  commitCuenta: '/plandecuentascontables/changetable/{MainAccountId}',
  getLastCaching: '/plandecuentascontables/getlastbuildcache',

  // Mantenedor de Proyectos
  getMantenedorProyectosGetAll: '/mantenedordeproyectos/getall?',
  getMantenedorProyectosListName: '/mantenedordeproyectos/getlist/{listname}',
  putMantenedorProyectosListUpdate: '/mantenedordeproyectos/updateoption',
  putMantenedorProyectosUpdate: '/mantenedordeproyectos/update',

  getMantenedorProyectosNewFound: '/mantenedordeproyectos/checkfoundnew',
  getMantenedorProyectosCheckNew: '/mantenedordeproyectos/checkfornew',
  getMantenedorProyectosTitleForNew: '/mantenedordeproyectos/gettitlefornew',
  saveMantenedorProyectosCommitNew: '/mantenedordeproyectos/commitnew',

  getMantenedorProyectosTitleFaena: '/mantenedordeproyectos/gettitlesfromfaena/{divkey}',
  getMantenedorProyectosTitleForAll: '/mantenedordeproyectos/gettitlesforallprojects',
  putMantenedorProyectosTitleMass: '/mantenedordeproyectos/massupdate',

  getMantenedorProyectosPlanDeNegocioGetLista: '/mantenedordeproyectos/planesdenegocio/getlistofdataproyecto/{idproyecto}',
  getMantenedorProyectosPlanDeNegocioGetData: '/mantenedordeproyectos/planesdenegocio/getdataproyecto/{idproyecto}/{year_plan}/{cuatrimestre}/{id_cuatrimestre}',
  postMantenedorProyectosPlanDeNegocio: '/mantenedordeproyectos/planesdenegocio/addnew',
  putMantenedorProyectosPlanDeNegocio: '/mantenedordeproyectos/planesdenegocio/update',

  getMantenedorProyectosInformacionPresupuestados: '/mantenedordeproyectos/informacionpresupuestados/getproyecto?',
  postMantenedorProyectosInformacionPresupuestados: '/mantenedordeproyectos/informacionpresupuestados/addpresupuestado',

  getMantenedorProyectosParticipacionesLista: '/mantenedordeproyectos/proyectosparticipacion/getlist/{idempresa}/{idproyecto}/{divkey}',
  postMantenedorProyectosParticipacionesInsertar: '/mantenedordeproyectos/proyectosparticipacion/insertparticipacion',
  deleteMantenedorProyectosParticipacionesDelete: '/mantenedordeproyectos/proyectosparticipacion/deleteparticipacion/{idempresa}/{idproyecto}/{divkey}/{idperiodo}',
  
  // Agregar Carga Externa
  postCargaExternosCreateNewProject: '/cargaexternos/createnewproject?',
  postCargaExternosCreateNewProjectAndCommitMantenedor: '/mantenedordeproyectos/createandcommitnewproject?',
  postCargaExternosCargaDatosTemp: '/cargaexternos/add_data/temp/{saldosacumulados}',
  postCargaExternosCargaDatos: '/cargaexternos/add_data/commit/{saldosacumulados}',
  getCargaExternosCargaCommitsByIdproyecto: '/cargaexternos/getcommitsbyidproyecto/{idproyecto}/{idperiodo}',
  getCargaExternosCargaCommitData: '/cargaexternos/getcommitdata/{hashid}',
  getCargaExternosActualizacionDataset: '/cargaexternos/checkdatabaseupdate/{hashid}',

  // Mantenedor Clasificacion MAO
  getMantenedorClasificacionMaoListaDeMao: '/mantenedorclasificacionmao/getmaolist',
  getMantenedorClasificacionMao: '/mantenedorclasificacionmao/getall?',
  getMantenedorClasificacionMaoCheckSinClasificar: '/mantenedorclasificacionmao/checksinclasificar/{option}',
  postMantenedorClasificacionMaoSaveCategoria: '/mantenedorclasificacionmao/savecategoria',
  postMantenedorClasificacionMaoSaveNewTextoClasificacion:'/mantenedorclasificacionmao/savenewtextoclasificacion',
  putMantenedorClasificacionMaoUpdateItem: '/mantenedorclasificacionmao/update',
  
  getChileDivision: '/mantenedordeproyectos/chiledivision',

  // EERR Indicadores data
  filterOld: '/eerr/data',
  filterSelectDataList: '/eerr/data_list/{tipo_filtro}',
  chartMargenes: '/eerr/nv2/margen_resultados/redis_key/filter?',
  chartTodosYear: '/eerr/totales_nv2/{idperiodo}/filter?',
  sliceData: '/eerr/nv2/consulta_exclusiva_inclusiva/a_origen/{redis_key}/{cuentaNV2}/periodo/{periodo}/filter?',
  // cuentasNv2: '/eerr/nv2/consulta_exclusiva_inclusiva_cuentas/{redis_key}/{cuentaNV2}/periodo/{periodo}/filter?',
  cuentasNv2: '/eerr/nv2/consulta_exclusiva_inclusiva_cuentas/{cuentaNV3}/periodo/{periodo}/filter?',
  eerrCuentasDetalle: '/eerr/balance_detalle/{idperiodo}/{ctacodigo}/filter?',
  
  // Detalle Cuentas
  cuentasSub: '/balance_detalle/{idperiodo}/{ctacodigo}/filter?',

  // EERR Directorio
  sliceDirectorioData: '/resultado_clasificado/{redis_key}/{year}/{periodo}/filter?',
  cuentasDirectorio: '/resultado_clasificado_por_cuenta/{redis_key}/{cuentaNV3}/{year}/{periodo}/filter?',

  // Balance General
  balanceGeneral: "/balance_general/{year}/{ultimo_periodo}/{cuenta_nv2}/filter?",
  balanceGeneralCuentas: "/balance_general_cuentas/{cuentaNV4}/{year}/{ultimo_periodo}/filter?",
  getBalanceGeneralByNV4: "/balance_general_get_by_nv4/{ultimo_periodo}/{cuentaNV4}/filter?",

  // Inventario
  InventarioDiario: '/balance_general_inventario/mes/{idperiodo}/{ctacodigo}/filter?',
  InventarioMensual: '/balance_general_inventario/year_hasta_mes/{idperiodo}/{ctacodigo}/filter?',

  // Imo
  imoData: "/imo/clasificacion_mensual/{year}/{periodo}/filter?",
  imoCuentas: "/imo/resultado_cuentas_clasificado/{idperiodo}/{imoNv2}/{imoNv3}/filter?",
  imoCuentaDetalle: "/imo/cuenta_detalle/{idperiodo}/{ctacodigo}/{imoNv2}/filter?",

  // Imo - PPTO
  getMaoPPTOData: "/imo_ppto/clasificacion_mensual/{year}/{periodo}/filter?",
  getMaoPPTODetalleMovimientos: "/imo_ppto/detalle_movimientos/{idperiodo}/{imoNv2}/{imoNv3}/filter?",
  getMaoPPTODetalleCategoria: "/imo_ppto/categoria_detalle/{idperiodo}/{abueloidcategoria}/{imoNv2}/filter?",
  getMaoPPTOChartVentas: "/imo_ppto/chart_ventas/{idperiodo}/{imoNv2}/{imoNv3}/filter?",
  getMaoPPTOChartVentasByIMO3: "/imo_ppto/chart_ventas_by_imo_3/{idperiodo}/{imoNv2}/{imoNv3}/filter?",

  // Proyectos Presupuestados
  getProyectosControlData: "/proyecto_presupuestos/{idperiodo}/filter?",
  getProyectosPresupuestosData: "/proyecto_presupuestos/presupuestos/{idperiodo}/filter?",
  getProyectosPresupuestosList: "/proyecto_presupuestos/lista_presupuestos/filter?",
  getProyectosPresupuestosNv2: "/proyecto_presupuestos/proyectos_presupuestos_nv2/{idperiodo}/{proyectosPresupuestosNv2}/{tipoSimplificado}/filter?",
  getProyectosPresupuestosChart: "/proyecto_presupuestos/chart_presupuestos/{idperiodo}/{tipoSimplificado}/{proyectosPresupuestosNv2}/{clasificaciones}/filter?",
  getProyectosPresupuestosChartAvanceIC: "/proyecto_presupuestos/chart_presupuestos_avance_ic/{idperiodo}/{proyectosPresupuestosNv2}/{clasificaciones}/filter?",
  getProyectosPresupuestosChartResultado: "/proyecto_presupuestos/chart_resultados_a_origen/{idperiodo}/{proyectosPresupuestosNv2}/{clasificaciones}/filter?",

  // Cotizaciones
  cotizaciones: '/consulta_cotizaciones/consultar?',
  cotizacionesPorFechaYMoneda: '/consulta_cotizaciones/consultar_fecha/{moneda}/{fecha}',

  // Caching tools
  reCaching: '/cache_tools/rebuildcache?',
  reCachingEERRBalanceMao: '/cache_tools/rebuildcache_only_eerr_balanace_mao?',

  // Evolutivos
  getEvolutivoEERR: '/eerr/nv2/montos_mensuales/{cuentaNV3}/periodo/{periodo}/filter?',
  getEvolutivoEERR_DesdeInicio:'/eerr/nv2/montos_desde_incio/{cuentaNV3}/periodo/{periodo}/filter?',
  getEvolutivoDirectorios: '/directorio/montos_mensuales/{cuentaNV3}/periodo/{periodo}/filter?',
  getEvolutivoBalance: '/balance_general_cuentas/montos_mensuales/{cuentaNV3}/periodo/{periodo}/filter?',
  getEvolutivoIMO: '/imo/montos_mensuales/{idperiodo}/imoNv3/{imoNv3}/filter?',
  getEvolutivoIMODesdeInicio: '/imo/montos_desde_inicio/{idperiodo}/imoNV3/{imoNv3}/filter?',

  // Consolidado
  getConsolidadoListaByNV3: '/eerr/consolidado/get_list_nv3/periodo/{periodo}/filter?',
  getConsolidadoByNV3: '/eerr/consolidado/get_by_nv3/{clasificacionNV3}/periodo/{periodo}/filter?',
  getCuentasConsolidadoByN3: '/eerr/consolidado/get_cuentas_nv3/{cuentaNV3}/periodo/{periodo}/filter?',

  getConsolidadoBalanceListaByNV4: '/balance/consolidado/get_all_nv/periodo/{periodo}/filter?',
  getConsolidadoBalanceByNV4: "/balance/consolidado/get_by_nv4/{clasificacionNV4}/periodo/{periodo}/filter?",
  getConsolidadoBalanceCuentas: "/balance/consolidado/cuentas/{cuentaNV4}/periodo/{ultimo_periodo}/filter?",
  getConsolidadoBalanceMontosMensuales: "/balance/consolidado/montos_mensuales/{cuentaNV3}/periodo/{periodo}/filter?",
  getConsolidadoBalancebyCuentas:"/balance/consolidado/get_by_cuenta/{cuentas}/periodo/{periodo}/filter?" ,
  getConsolidadoBalanceCuentasSubgrupo: "/balance/consolidado/cuentas_subgrupo/{cuentaNV4}/periodo/{ultimo_periodo}/filter?",
  getConsolidadoBalanceDinamico: "/balance/consolidado/get_by_dinamico/{clasificacionNV4}/periodo/{periodo}/filter?",

  //Mantenedor Empresas
  getMantenedorEmpresasLista: '/empresas/listaempresas',
  putMantenedorEmpresasUpdate: '/empresas/update_datos_empresa' ,
  postMantenedorEmpresasAdd: '/empresas/crearempresa', 

  //Socios
  getSociosLista:'/socios/listasocios',
  postSociosAdd:'/socios',

  //Reporte Mensual
  getProyectoInfo: '/reporte_mensual/data_info/{idperiodo}/filter?',

  // Mantenedor de Maquinarias
  getMantenedorMaquinariasGetAll: '/mantenedor_maquinarias/getall?',
  getMantenedorMaquinariasGetListOfUniqueData: '/mantenedor_maquinarias/get_unique_list_data',
  postMantenedorMaquinariasAdd: '/mantenedor_maquinarias/addnew',
  postMantenedorMaquinariasAddInBulk: '/mantenedor_maquinarias/massaddnew',
  putMantenedorMaquinariasUpdate: '/mantenedor_maquinarias/updatemaquinaria',
  getMantenedorMaquinariasNewFound: '/mantenedor_maquinarias/checkfornew',

  // Mantenedor de Clasificaciones Tipocosto de Maquinarias
  getMantenedorClasificacionesTipocostoMaquinariasGetAll: '/mantenedor_maquinarias/clasificacion_tipocosto/getall?',
  getMantenedorClasificacionesTipocostoMaquinariasGetTextosTipocostos: '/mantenedor_maquinarias/clasificacion_tipocosto/get_tipocostos_textos',
  postMantenedorClasificacionTipocostoMaquinariasSaveItem: '/mantenedor_maquinarias/clasificacion_tipocosto/addnew',
  putMantenedorClasificacionTipocostoMaquinariasUpdateItem: '/mantenedor_maquinarias/clasificacion_tipocosto/update',
  getMantenedorClasificacionesTipocostoMaquinariasNewFound: '/mantenedor_maquinarias/clasificacion_tipocosto/checknew',
  postMantenedorClasificacionTipocostoMaquinariasSaveNewTextoClasificacion:'/mantenedor_maquinarias/clasificacion_tipocosto/savenewtextoclasificacion',

  // Maquinarias EERR
  getMaquinariasTipocosto: '/maquinaria/tipocostoingreso/periodo/{idperiodo}/filter?',
  getMaquinariasTipocostoIDProyectoByClasificacion: '/maquinaria/tipocostoingreso/clasificaciones/{nombre_categoria}/periodo/{idperiodo}/filter?',
  getMaquinariasTipocostoIDProyectoTransacciones: '/maquinaria/tipocostoingreso/idproyecto/{idproyecto}/clasificaciones/{nombre_categoria}/periodo/{idperiodo}/filter?',
  getMaquinariasTipocostoByClasificacionNv3: '/maquinaria/tipocostoingreso/clasificacion_nv3/{nombre_categoria}/periodo/{idperiodo}/filter?',
  
  //Comparativo Contabilidad - Proyecto
  getComparativeDataSummary:'/comparativo/data/resumen',
  getComparativeDataCuentaContProy:'/comparativo/data/detailed/{idproyecto}',
}  